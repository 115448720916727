<template>
    <input class="form-control max-w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brand focus:outline-none" :placeholder="getPlaceHolder" :type="getInputType" v-model="getValue" />
</template>

<script>
export default {
    props: {
        fieldData: {
            type: Object,
            require: true,
        },
    },
    computed: {
        getInputType() {
            if (this.fieldData.inputType && this.fieldData.inputType === "phone") return "tel";
            else return this.fieldData.inputType || "text";
        },
        getPlaceHolder() {
            return this.fieldData.placeholder || "";
        },
        getValue: {
            get() {
                return this.fieldData.value || "";
            },
            set(newValue) {
                // let data = { value: newValue, field_id: this.fieldData.field_id };
                // if (this.fieldData.multi_id) {
                //     data.multi_id = this.fieldData.multi_id;
                //     delete data.field_id
                // }
                // EventBus.$emit("changeFieldValue", data);
                this.$emit('input', newValue);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
</style>